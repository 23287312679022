@import "base";

#footer {
	.topper {
		@include box_shadow(rgba(0, 0, 0, 0.15) 0px 3px 3px 0 inset);
		border-bottom: 4px solid #055eae;
		.container {
			width: 940px;
			margin: auto;
			padding: 22px 0;
			text-align: right;
			a {
				display: inline-block;
				margin-right: 20px;
			}
		}
	}
	.middle {
		.container {
			width: 940px;
			margin: auto;
			padding: 22px 0;
			overflow: hidden;
		}
		.left {
			float: left;
			white-space: nowrap;
		}
		.right {
			float: right;
			width: 640px;
		}
		h2 {
			font: {
				size: 14px;
				weight: 500;
			}
		}
		p {
			font: {
				size: 12px;
			}
			line-height: 1.5;
		}
		.symbol {
			padding: 45px 0;
			text-align: center;
		}
		a {
			color: $baseColor;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
			&[target="blank"] {
				&:after {
					content: url(/src/img/marker/blank_a.png);
					margin: 0 3px;
					vertical-align: 0.2em;
				}
			}
		}
		.bannerContainer {
			@include borderBox;
			background: #f5f5f5;
			border: 1px solid #ccc;
			padding: 10px 0;
			overflow: hidden;
			li {
				padding: 0 9px 16px;
				width: 300px;
				float: left;
				h2 {
					text-align: center;
					color: $baseColor;
					margin-bottom: 18px;
					font: {
						size: 15px;
						weight: bold;
					}
				}
				h3 {
					text-align: center;
					margin-bottom: 5px;
					font: {
						size: 12px;
						weight: bold;
					}
					line-height: 1.5;
				}
				p {
					text-align: center;
					@include font_size(12);
					line-height: 1.5;
				}
			}
			li:nth-child(even){
				border-left: 1px solid #ccc;
			}
		}
	}
	.bottom {
		background: $baseColor;
		padding: 28px 0 80px;
		.container {
			width: 940px;
			margin: auto;
			color: #fff;
		}
		.symbol {
			text-align: center;
			img {
				margin: 0 3px;
			}
			margin-bottom: 30px;
		}
		.nav {
			text-align: center;
			margin-bottom: 24px;
			li {
				display: inline-block;
				padding: 0 15px;
				border-left: 1px solid #fff;
				a {
					@include font_size(12);
					text-decoration: none;
					color: #fff;
					&:hover {
						text-decoration: underline;
					}
				}
				&:first-child {
					border-left: none;
				}
			}
		}
		.copyright {
			text-align: center;
			small {
				color: #fff;
				@include font_size(11);
			}
		}

	}
}