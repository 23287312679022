@import "base", "reset", "module", "baseStyle", "header", "subHeader", "footer";

#wrapper {
	width: 100%;
	min-width: 960px;
	position: relative;
  -ms-overflow-y: auto;
  overflow-y: auto;
  -ms-overflow-x: hidden;
  overflow-x: hidden;
}

#container {
	background: $bgColor;
	@include box_shadow(rgba(2, 92, 173, 0.1) 0px 1px 1px 1px inset, rgba(246, 233, 201, 1) 0px 4px 0px 2px inset);
    #contents {
        width: 940px;
        margin: auto;
        padding: 14px 0 10px;
        overflow: hidden;
    }
}

$i: 1;
@while $i <= 4 {
    #container_step#{$i} {
      background: $bgColor;
      @include box_shadow(rgba(2, 92, 173, 0.1) 0px 1px 1px 1px inset, rgba(246, 233, 201, 1) 0px 4px 0px 2px inset);
      #contents_step#{$i} {
        width: 940px;
        margin: auto;
        padding: 14px 0 10px;
        overflow: hidden;
      }
    }
  $i: $i + 1;
}


.bnr_a {
	display: inline-block;
	padding: 4px;
	border: 1px solid #ccc;
	background: #fff;
	&:hover {
		background: #014496;
	}
}


hr.groove {
    border-top: 2px solid #e6dfcd;
    border-bottom: 1px solid #fff;
    margin: 10px 0;
}