@import "base";

p {
	@include font_size(13);
	#contents & {
		margin: 0 0 10px;
		line-height: 1.5;
	}
}
h1 {
	margin: 0 0 10px;
}
input[type="text"],input[type="password"],input[type="number"] {
	@include borderBox;
	@include appearance;
	@include font_size(13);
	@include border_radius(4px);
	@include box_shadow(0 1px 1px rgba(195, 195, 195, 0.3));
	padding: 9px;
	background-color: #fff;
	border: 1px solid #ccc;
}
.select {
	overflow: hidden;
	display: inline-block;
	background-color: #fff;
	background-image: url(/src/img/marker/bg_select.png);
	background-repeat: no-repeat;
	background-position: right center;
	@include box_shadow(0 2px 3px rgba(0, 0, 0, 0.24), 0 0 3px rgba(204, 204, 204, 0.75));
	@include border_radius(4px);
	select {
		background: transparent;
		width: 200%;
		@include borderBox;
		@include appearance;
		@include font_size(13);
		padding: 9px;
		padding-right: 35px;
		outline: none !important;
		  -moz-appearance: none;
		  text-indent: 0.01px;
		  text-overflow: '';
	}
	select::-ms-expand {
	  display: none;
	}
}


textarea {
	background-color: white;
	border: 1px solid #ccc;
	@include box_shadow(0 1px 1px rgba(195, 195, 195, 0.75), inset 2.5px 4.3px 5px rgba(204, 204, 204, 0.15));
}




 img.rover {
 	cursor: pointer;
 }

 ul {
 	&.ul_disc {
		margin: 0 10px 20px;
		padding-left: 30px;
		li {
			@include font_size(13);
			margin-bottom: 10px;
			list-style-type: disc;
			list-style-position: outside;
			line-height: 1.3
		}
 	}
 }