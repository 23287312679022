@import "base";
#header {
	background: $baseColor;
	overflow: hidden;
	.container {
		width: 960px;
		margin: 0 auto;
	}
	.symbol {
		float: left;
		margin: 16px 0 0 29px
	}
}

#utility_nav {
	float: right;
	ul {
		background: #fff;
		overflow: hidden;
		padding-right: 4px;
		li {
			float: left;
			padding: 9px 0;
			a {
				display: block;
				@include font_size(12);
				text-decoration: none;
				padding: 3px 15px;
				border-left: 1px solid #c9c9c9;
			}
			&:first-child a {
				border-left: none;
			}
		}
	}
}

#global_nav {
	float: right;
	clear: right;
	ul {
		border-top: 1px solid #062c5d;
		border-right: 1px solid #062c5d;
		overflow: hidden;
		li {
			float: left;
			border-left: 1px solid #062c5d;
			a {
				background: $baseColor;
				display: block;
				width: 149px;
				text-align: center;
				padding: 16px 0 14px;
				border-top: 1px solid #1b6db4;
				border-right: 1px solid #1b6db4;
				border-left: 1px solid #4185c1;
				&:hover {
					padding: 16px 0 13px;
					background: #014496;
					border-top: 1px solid #1b6db4;
					border-right: 1px solid #1b57a1;
					border-bottom: 1px solid #1b57a1;
					border-left: 1px solid #1b57a1;
				}
				&.current {
					padding: 16px 0 13px;
					background: #0075c2;
					border-top: 1px solid #1b6db4;
					border-right: 1px solid #1b6db4;
					border-bottom: 1px solid #1b6db4;
					border-left: 1px solid #4098d1;
				}
				&.current:hover {
					padding: 16px 0 13px;
					background: #0075c2;
					border-top: 1px solid #1b6db4;
					border-right: 1px solid #1b6db4;
					border-bottom: 1px solid #1b6db4;
					border-left: 1px solid #4098d1;
					cursor: default;
				}
			}
			&.member {
				a {
					padding: 16px 0 14px;
					background: #093a6b;
					border-top: 1px solid #486387;
					border-right: 1px solid #224e7a;
					border-bottom: 1px solid #093a6b;
					border-left: 1px solid #476b90;
					&:hover {
						padding: 16px 0 14px;
						background: #052b5d;
						border-top: 1px solid #486387;
						border-right: 1px solid #1e416e;
						border-bottom: 1px solid #1e416e;
						border-left: 1px solid #1e416e;
					}
					&.current {
						padding: 16px 0 14px;
						background: #184988;
						border-top: 1px solid #486387;
						border-right: 1px solid #305c94;
						border-bottom: 1px solid #305c94;
						border-left: 1px solid #305c94;
					}
					&.current:hover {
						background: #184988;
						border-top: 1px solid #486387;
						border-right: 1px solid #305c94;
						border-bottom: 1px solid #305c94;
						border-left: 1px solid #305c94;
					}
				}
			}
		}
	}
}
