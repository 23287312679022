@import "base";

$i: 1;
@while $i <= 4 {
  #subHeader_#{$i} {
    background: #fff;
    border-top: 2px solid #e0e0e0;
    border-bottom: 2px solid #025cad;
    .container {
      width: 940px;
      margin: 0 auto;
      overflow: hidden;
    }
    h1 {
      float: left;
      margin: 26px 0 23px;
    }
    #progress_#{$i} {
      float: right;
      overflow: hidden;
      li {
        width: 156px;
        float: left;
        padding: 11px 0 10px;
      }
    }
  }
  $i: $i + 1;
}


#subHeader {
  background: #fff;
  border-top: 2px solid #e0e0e0;
  border-bottom: 2px solid #025cad;
  .container {
    width: 940px;
    margin: 0 auto;
    overflow: hidden;
  }
  h1 {
    float: left;
    margin: 26px 0 23px;
  }
}