@import "base";
/*reset*/
html { -webkit-text-size-adjust: 100% ; height: 100%; font-size: 62.5%;}
body { margin: 0; padding: 0; height: 100%; }
body * { margin: 0; padding: 0; border: none; outline: none; font-weight: normal; list-style: none; font-size: 100%; line-height: 1; color: $textColor; }
html * { font-family: Verdana, sans-serif; }
html.mac * { font-family: Verdana, "游ゴシック体", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", sans-serif; }
html.win * { font-family: Verdana, "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; letter-spacing: 1px; }
img { border: 0; vertical-align: bottom; }
span { line-height: inherit; }
strong { font-weight: bold; line-height: inherit; }
a { line-height: inherit; }